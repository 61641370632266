import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Confetti from "react-confetti"; 
import "./fonts.css"; // Import confetti package
import "./Quizover.css";
import "./Quizoversmallview.css";
import "./Quizoversmallviewiphone.css";
import "./Quizoversmallviewiphonepromax.css";

function Congratsquizover() {
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [showConfetti, setShowConfetti] = useState(true);

  // Stop confetti after 5 seconds
  useEffect(() => {
    const confettiTimeout = setTimeout(() => {
      setShowConfetti(false);
    }, 6000); // 5000ms = 5 seconds

    return () => clearTimeout(confettiTimeout); // Cleanup on unmount
  }, []);

  // Update window size for confetti
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Scroll to the top of the page when the component loads
 

  return (
    <div className="congrats-wrapper">
      {/* Confetti Component for 5 seconds */}
      {showConfetti && (
        <Confetti
          width={windowSize.width}
          height={windowSize.height}

        />
      )}

      <div className="congrats-card">
        {/* Circular medal with avatar */}
        <div className="medal-container">
          <div
            className="medal-frame"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/circularcrown.png)`,
            }}
          ></div>
          <div
            className="user-avatar"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/girl.svg)`,
            }}
          ></div>
        </div>

        {/* Rank and Score */}
        <div className="rank-container">
          <h1>CONGRATULATIONS</h1>
          <p className="attmpted">YOUR QUIZ ATTEMPT HAS BEEN SUCCESFULLY SUBMITTED.</p>

          <div className="rank-score hide">
            <div className="rank">
              <p>OVERALL RANK</p>
              <h2>#06</h2>
            </div>
            <div className="score">
              <p>OVERALL SCORE</p>
              <h2>2,520 QP</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Congratsquizover;
