import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField
} from '@mui/material';

function GiveawayAdminWinners() {
  const [giveaways, setGiveaways] = useState([]);
  const [selectedGiveawayId, setSelectedGiveawayId] = useState(null);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [updateOrder, setUpdateOrder] = useState(1);
  const [updateQuote, setUpdateQuote] = useState("");
  const [updateName, setUpdateName] = useState("");
  const [updateImage, setUpdateImage] = useState(null);
  const [addOrder, setAddOrder] = useState(1);
  const [addQuote, setAddQuote] = useState("");
  const [addName, setAddName] = useState("");
  const [addImage, setAddImage] = useState(null);
  const [password, setPassword] = useState('');
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(true);

  useEffect(() => {
    if (!passwordDialogOpen) {
      fetchGiveaways();
    }
  }, [passwordDialogOpen]);

  const fetchGiveaways = () => {
    axios.get('https://scoreapi.cricfan.tv/api/get_giveawaywinners', { params: { password } })
      .then(response => {
        setGiveaways(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching giveaways:', error);
      });
  };

  const handleUpdateGiveaway = () => {
    const formData = new FormData();
    formData.append('file', updateImage);
    formData.append('winner_id', selectedGiveawayId);
    formData.append('order', updateOrder);
    formData.append('quote', updateQuote);
    formData.append('name', updateName);
    formData.append('password', password);

    axios.post('https://scoreapi.cricfan.tv/api/post_update_giveawaywinners', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        console.log('Giveaway updated successfully');
        setUpdateDialogOpen(false);
        fetchGiveaways();
      })
      .catch(error => {
        console.error('Error updating giveaway:', error);
      });
  };

  const handleUpdateDialogOpen = (giveaway) => {
    setSelectedGiveawayId(giveaway.id);
    setUpdateOrder(giveaway.winner_order);
    setUpdateQuote(giveaway.quote);
    setUpdateName(giveaway.name);
    setUpdateDialogOpen(true);
  };

  const handleUpdateDialogClose = () => {
    setUpdateDialogOpen(false);
    setSelectedGiveawayId(null);
    setUpdateOrder(1);
    setUpdateQuote("");
    setUpdateName("");
    setUpdateImage(null);
  };

  const handleRemoveGiveaway = () => {
    axios.post('https://scoreapi.cricfan.tv/api/post_remove_giveawaywinners', {
      winner_id: selectedGiveawayId,
      password
    })
      .then(response => {
        console.log('Giveaway removed successfully');
        setRemoveDialogOpen(false);
        fetchGiveaways();
      })
      .catch(error => {
        console.error('Error removing giveaway:', error);
      });
  };

  const handleRemoveDialogOpen = (giveaway) => {
    setSelectedGiveawayId(giveaway.id);
    setRemoveDialogOpen(true);
  };

  const handleRemoveDialogClose = () => {
    setRemoveDialogOpen(false);
    setSelectedGiveawayId(null);
  };

  const handleAddGiveaway = () => {
    const formData = new FormData();
    formData.append('file', addImage);
    formData.append('order', addOrder);
    formData.append('quote', addQuote);
    formData.append('name', addName);
    formData.append('password', password);

    axios.post('https://scoreapi.cricfan.tv/api/post_add_giveawaywinners', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        console.log('Giveaway added successfully');
        setAddDialogOpen(false);
        fetchGiveaways();
      })
      .catch(error => {
        console.error('Error adding giveaway:', error);
      });
  };

  const handlePasswordSubmit = () => {
    setPasswordDialogOpen(false);
  };

  return (
    <div>
      <h2 style={{ color: '#AF9661' }}>Giveaway Management</h2>
      <Dialog open={passwordDialogOpen} onClose={() => { }} disableBackdropClick>
        <DialogTitle>Enter Password</DialogTitle>
        <DialogContent>
          <TextField
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePasswordSubmit} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Submit</Button>
        </DialogActions>
      </Dialog>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
        <Button onClick={() => setAddDialogOpen(true)} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Add Giveaway</Button>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>ID</TableCell>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Image URL</TableCell>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Order</TableCell>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Quote</TableCell>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Name</TableCell>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {giveaways.map(giveaway => {
              return (
                <TableRow key={giveaway.id}>
                  <TableCell>{giveaway.id}</TableCell>
                  <TableCell>{giveaway.photo}</TableCell>
                  <TableCell>{giveaway.winner_order}</TableCell>
                  <TableCell>{giveaway.quote}</TableCell>
                  <TableCell>{giveaway.name}</TableCell>
                  <TableCell>
                    <Button onClick={() => handleUpdateDialogOpen(giveaway)} variant="contained" color="primary" style={{ marginRight: '8px' }}>Update</Button>
                    <Button onClick={() => handleRemoveDialogOpen(giveaway)} variant="contained" color="secondary">Remove</Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={updateDialogOpen} onClose={handleUpdateDialogClose}>
        <DialogTitle>Update Giveaway</DialogTitle>
        <DialogContent>
          <TextField
            label="Order"
            type="number"
            value={updateOrder}
            onChange={(e) => setUpdateOrder(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Quote"
            value={updateQuote}
            onChange={(e) => setUpdateQuote(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Name"
            value={updateName}
            onChange={(e) => setUpdateName(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <input
            type="file"
            onChange={(e) => setUpdateImage(e.target.files[0])}
            style={{ marginTop: '16px', marginBottom: '16px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdateGiveaway} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Update</Button>
          <Button onClick={handleUpdateDialogClose} variant="contained" color="secondary">Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={removeDialogOpen} onClose={handleRemoveDialogClose}>
        <DialogTitle>Remove Giveaway Winner</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to remove this giveaway winner?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemoveGiveaway} variant="contained" color="secondary">Remove</Button>
          <Button onClick={handleRemoveDialogClose} variant="contained">Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)}>
        <DialogTitle>Add Giveaway</DialogTitle>
        <DialogContent>
          <TextField
            label="Order"
            type="number"
            value={addOrder}
            onChange={(e) => setAddOrder(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Quote"
            value={addQuote}
            onChange={(e) => setAddQuote(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Name"
            value={addName}
            onChange={(e) => setAddName(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <input
            type="file"
            onChange={(e) => setAddImage(e.target.files[0])}
            style={{ marginTop: '16px', marginBottom: '16px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddGiveaway} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Add</Button>
          <Button onClick={() => setAddDialogOpen(false)} variant="contained" color="secondary">Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default GiveawayAdminWinners;