import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './fonts.css';
import './PreviousWinner.css';
import Quote from './Quote';

function PreviousWinner() {
  const [winners, setWinners] = useState([]);

  useEffect(() => {
    axios.get('https://scoreapi.cricfan.tv/api/get_giveawaywinners')
      .then(response => {
        if (response.data.isSuccess) {
          setWinners(response.data.data);
        }
      })
      .catch(error => {
        console.error('Error fetching winners:', error);
      });
  }, []);

  return (
    <div>
      <div>
        <p className="winner-header-style">PREVIOUS WINNERS</p>
      </div>
      <div className="winner-container">
        {winners.map((winner, index) => (
          <div className="winner-item" key={winner.id}>
            <img
              src={winner.photo}
              alt="Winner"
              className="image-style img-fluid"
              style={{ width: '95%', height: 'auto' }}
            />
            <Quote
              quote={winner.quote}
              name={winner.name}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PreviousWinner;