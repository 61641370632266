import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import Nav from './Nav';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoadingIcon from './LoadingIcon'; // Import your loading icon component
import TelegramIcon from './TelegramIcon'; // Import the TelegramIcon component
import './App.css';
import UserHeader from './UserHeader';
import './UserProfile.css';
import { TextField, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { showSuccessPopup, showErrorPopup } from './Notification';

const WhiteTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    color: 'white', // Text color
  },
  '& .MuiInputLabel-root': {
    color: 'white', // Label color
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white', // Default border color
    },
    '&:hover fieldset': {
      borderColor: 'white', // Hover border color
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white', // Focused border color
    },
  },
});

const GreenButton = styled(Button)({
  padding: '10px 20px',
  borderRadius: '5px',
  backgroundColor: '#01FF76',
  color: 'black',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#00cc60',
  },
});

function UserProfile() {
  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState(null);
  const [selectedOption, setSelectedOption] = useState('Profile');
  const [competitionsData, setCompetitionsData] = useState({ current: [], past: [] });
  const [currentCompetitionActive, setCurrentCompetitionActive] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');

  const [isProfileEditing, setIsProfileEditing] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [age, setAge] = useState('');
  const [place, setPlace] = useState('');
  const [team, setTeam] = useState('');
  const [instaid, setInstaId] = useState('');

  const fetchProfileData = async () => {
    const token = localStorage.getItem('jwtToken');
    try {
      const response = await fetch(`https://scoreapi.cricfan.tv/api/get_profile_data?token=${token}`);
      const jsonData = await response.json();

      if (jsonData.message === 'expire') {
        localStorage.removeItem('jwtToken');
        window.location.href = '/';
      } else if (jsonData.status === 200) {
        const data = jsonData.data[0];
        setProfileData(data);
        setName(data.name);
        setEmail(data.emailid);
        setAge(data.age);
        setPlace(data.place);
        setTeam(data.team);
        setInstaId(data.insta_id);
        if (data.address) {
          const [addressLine, city, state, country] = data.address.split('||');
          setAddress(addressLine || '');
          setCity(city || '');
          setState(state || '');
          setCountry(country || '');
        }
      } else {
        console.error('Error fetching profile data:', jsonData);
      }
    } catch (error) {
      console.error('Error fetching profile data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCompetitionsData = async () => {
    const token = localStorage.getItem('jwtToken');
    try {
      const response = await fetch(`https://scoreapi.cricfan.tv/api/get_user_competitions_details?token=${token}`);
      const jsonData = await response.json();
      if (jsonData.status === 200) {
        setCompetitionsData({ current: jsonData.current, past: jsonData.past });
      } else {
        console.error('Error fetching competitions data:', jsonData);
      }
    } catch (error) {
      console.error('Error fetching competitions data:', error);
    }
  };

  const logoutFunction = async () => {
    localStorage.removeItem('jwtToken');
    window.location.href = '/';
  };

  useEffect(() => {
    fetchProfileData();
    fetchCompetitionsData();
  }, []);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleEditAddress = () => {
    setIsEditing(true);
  };

  const handleEditProfile = () => {
    setIsProfileEditing(true);
  };

  const handleSaveAddress = async () => {
    if (address && city && state && country) {
      const combinedAddress = `${address}||${city}||${state}||${country}`;
      const token = localStorage.getItem('jwtToken');
      try {
        const response = await fetch(`https://scoreapi.cricfan.tv/api/get_update_profile_data?token=${token}&address=${combinedAddress}`);
        const jsonData = await response.json();
        if (jsonData.status === 200) {
          setProfileData({
            ...profileData,
            address: combinedAddress,
          });
          setIsEditing(false);
          showSuccessPopup("Address updated successfully!");
        } else {
          showErrorPopup("Error updating address");
        }
      } catch (error) {
        showErrorPopup("Error updating address");
        console.error('Error saving address:', error);
      }
    } else {
      showErrorPopup("All address fields must be filled");
    }
  };

  const handleSaveProfile = async () => {
    const token = localStorage.getItem('jwtToken');
    let apiUrl = `https://scoreapi.cricfan.tv/api/get_update_profile_data?token=${token}`;

    // Check which fields are provided and append them to the API URL
    if (email) apiUrl += `&email=${email}`;
    if (age) apiUrl += `&age=${age}`;
    if (place) apiUrl += `&place=${place}`;
    if (team) apiUrl += `&team=${team}`;
    if (instaid) apiUrl += `&insta_id=${instaid}`;
    if (name) apiUrl += `&name=${name}`;

    try {
      const response = await fetch(apiUrl);
      const jsonData = await response.json();
      if (jsonData.status === 200) {
        fetchProfileData();
        setIsProfileEditing(false);
        showSuccessPopup("Profile data updated successfully!");
      } else {
        showErrorPopup("Error updating profile data");
      }
    } catch (error) {
      showErrorPopup("Error updating profile data");
      console.error('Error saving profile data:', error);
    }
  };

  return (
    <div className="App" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/cricfan_bg.png)`, minHeight: '100vh', fontSize: '30px' }}>
      <Nav />
      <UserHeader />
      <div className="container">
        <div className="vertical-menu">
          <div className={selectedOption === 'Profile' ? 'menu-item active' : 'menu-item'} onClick={() => handleOptionSelect('Profile')}>Profile</div>
          <div className={selectedOption === 'Address' ? 'menu-item active' : 'menu-item'} onClick={() => handleOptionSelect('Address')}>Address</div>
          <div className={selectedOption === 'Competitions' ? 'menu-item active' : 'menu-item'} onClick={() => handleOptionSelect('Competitions')}>Competitions</div>
          <div className={selectedOption === 'Logout' ? 'menu-item active' : 'menu-item'} onClick={() => logoutFunction()}>Logout</div>
        </div>
        <div className="data">
          <section className="profile">
            {loading ? (
              <LoadingIcon />
            ) : (
              <>
                {selectedOption === 'Profile' && profileData && (
                  <div className="profile-data">
                    <p>
                      Hello, <strong><em>{profileData.name}</em></strong>. (Not <strong><em>{profileData.name}</em></strong>?
                      <a href="#" onClick={logoutFunction} style={{ textDecoration: 'underline', cursor: 'pointer' }}>Logout</a>)
                    </p>
                    <p>You have {profileData.tickets || 0} tickets to enter the live competitions!</p>
                    <div className="contact-info">
                      {isProfileEditing ? (
                        <>
                          <div className="">
                            <WhiteTextField
                              label="Name"
                              variant="outlined"
                              fullWidth
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              margin="normal"
                            />
                          </div>
                          <div className="">
                            <WhiteTextField
                              label="Email"
                              variant="outlined"
                              fullWidth
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              margin="normal"
                            />
                          </div>
                          <div className="">
                            <WhiteTextField
                              label="Age"
                              variant="outlined"
                              fullWidth
                              value={age}
                              onChange={(e) => setAge(e.target.value)}
                              margin="normal"
                            />
                          </div>
                          <div className="">
                            <WhiteTextField
                              label="Place"
                              variant="outlined"
                              fullWidth
                              value={place}
                              onChange={(e) => setPlace(e.target.value)}
                              margin="normal"
                            />
                          </div>
                          <div className="">
                            <WhiteTextField
                              label="Favorite Team"
                              variant="outlined"
                              fullWidth
                              value={team}
                              onChange={(e) => setTeam(e.target.value)}
                              margin="normal"
                            />
                          </div>
                          <div className="">
                            <WhiteTextField
                              label="Instagram ID"
                              variant="outlined"
                              fullWidth
                              value={instaid}
                              onChange={(e) => setInstaId(e.target.value)}
                              margin="normal"
                            />
                          </div>
                          <GreenButton variant="contained" color="primary" onClick={handleSaveProfile}>
                            Save
                          </GreenButton>
                        </>
                      ) : (
                        <>
                          <div className="info-box">
                            <p>Phone: {profileData.phoneNumber || ''}</p>
                            <p>Email: {profileData.emailid || ''}</p>
                            <p>Age: {profileData.age || ''}</p>
                            <p>Gender: {profileData.gender || ''}</p>
                            <p>Place: {profileData.place || ''}</p>
                            <p>Favorite Team: {profileData.team || ''}</p>
                            <p>Instagram ID: {profileData.insta_id || ''}</p>
                          </div>
                          <GreenButton variant="contained" color="primary" onClick={handleEditProfile}>
                            Edit
                          </GreenButton>
                        </>
                      )}
                    </div>
                  </div>
                )}

                {selectedOption === 'Address' && profileData && (
                  <div className="profile-data">
                    <div className="contact-info">
                      {isEditing ? (
                        <>
                          <div className="">
                            <WhiteTextField
                              label="Address Line"
                              variant="outlined"
                              fullWidth
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                              margin="normal"
                            />
                          </div>
                          <div className="">
                            <WhiteTextField
                              label="City"
                              variant="outlined"
                              fullWidth
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                              margin="normal"
                            />
                          </div>
                          <div className="">
                            <WhiteTextField
                              label="State"
                              variant="outlined"
                              fullWidth
                              value={state}
                              onChange={(e) => setState(e.target.value)}
                              margin="normal"
                            />
                          </div>
                          <div className="">
                            <WhiteTextField
                              label="Country"
                              variant="outlined"
                              fullWidth
                              value={country}
                              onChange={(e) => setCountry(e.target.value)}
                              margin="normal"
                            />
                          </div>
                          <GreenButton variant="contained" color="primary" onClick={handleSaveAddress}>
                            Save
                          </GreenButton>
                        </>
                      ) : (
                        <>
                          {(!profileData.address && !profileData.city && !profileData.state && !profileData.country) ? (
                            <p>You have not entered your address. Add it now</p>
                          ) : (
                            <div className="info-box">
                              <p>Address: {address || ''}</p>
                              <p>City: {city || ''}</p>
                              <p>State: {state || ''}</p>
                              <p>Country: {country || ''}</p>
                            </div>
                          )}
                          <GreenButton variant="contained" color="primary" onClick={handleEditAddress}>
                            Edit
                          </GreenButton>
                        </>
                      )}
                    </div>
                  </div>
                )}

                {selectedOption === 'Competitions' && (
                  <div className="competitions-data">
                    <p>Once you enter a competition, it will appear here. Good luck!</p>
                    <div className="horizontal-menu">
                      <div className={currentCompetitionActive ? "menu-item-comp active" : "menu-item-comp"} onClick={() => setCurrentCompetitionActive(true)}>Current Competitions</div>
                      <div className={!currentCompetitionActive ? "menu-item-comp active" : "menu-item-comp"} onClick={() => setCurrentCompetitionActive(false)}>Past Competitions</div>
                    </div>
                    <div className="competitions-list-container">
                      <div className="competitions-list">
                        {currentCompetitionActive ? (
                          <div className="competitions-list">
                            {competitionsData.current.map((competition) => (
                              <div key={competition.id} className="competition-card">
                                <h3>{competition.prize_title}</h3>
                                <p>Draw Date: {new Date(competition.draw_date).toLocaleDateString()}</p>
                                <p>Tickets: {competition.tickets}</p>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="competitions-list">
                            {competitionsData.past.map((competition) => (
                              <div key={competition.id} className="competition-card">
                                <h3>{competition.prize_title}</h3>
                                <p>Draw Date: {new Date(competition.draw_date).toLocaleDateString()}</p>
                                <p>Tickets: {competition.tickets}</p>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </section>
        </div>
      </div>
      <Footer />
      <TelegramIcon />
    </div>
  );
}

export default UserProfile;