import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField
} from '@mui/material';

function GiveawayAdminLiveQuizQuestions() {
  const [questions, setQuestions] = useState([]);
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [updateQuestion, setUpdateQuestion] = useState("");
  const [updateOptionA, setUpdateOptionA] = useState("");
  const [updateOptionB, setUpdateOptionB] = useState("");
  const [updateOptionC, setUpdateOptionC] = useState("");
  const [updateOptionD, setUpdateOptionD] = useState("");
  const [updateCorrectOption, setUpdateCorrectOption] = useState(1);
  const [updateIsActive, setUpdateIsActive] = useState(true);
  const [addQuestion, setAddQuestion] = useState("");
  const [addOptionA, setAddOptionA] = useState("");
  const [addOptionB, setAddOptionB] = useState("");
  const [addOptionC, setAddOptionC] = useState("");
  const [addOptionD, setAddOptionD] = useState("");
  const [addCorrectOption, setAddCorrectOption] = useState(1);
  const [addIsActive, setAddIsActive] = useState(true);
  const [password, setPassword] = useState('');
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(true);
  const [quizId, setQuizId] = useState(0);

  const location = useLocation();

  useEffect(() => {
    if (!passwordDialogOpen) {
      setQuizId(location.state?.quizId);
      fetchQuestions(location.state?.quizId);
    }
  }, [passwordDialogOpen]);

  const fetchQuestions = (quizId) => {
    axios.get(`https://quizapi.cricfan.tv/admin/live-quiz-questions/${quizId}`, { params: { password } })
      .then(response => {
        setQuestions(response.data);
      })
      .catch(error => {
        toast.error(error.response.data.message);
        console.error('Error fetching questions:', error);
      });
  };

  const handleUpdateQuestion = () => {
    axios.put(`https://quizapi.cricfan.tv/admin/live-quiz-questions/${selectedQuestionId}`, {
      question: updateQuestion,
      optionA: updateOptionA,
      optionB: updateOptionB,
      optionC: updateOptionC,
      optionD: updateOptionD,
      correct_option: updateCorrectOption,
      is_active: updateIsActive
    }, { params: { password } })
      .then(response => {
        console.log('Question updated successfully');
        setUpdateDialogOpen(false);
        fetchQuestions(quizId);
      })
      .catch(error => {
        toast.error(error.response.data.message);
        console.error('Error updating question:', error);
      });
  };

  const handleUpdateDialogOpen = (question) => {
    setSelectedQuestionId(question.question_id);
    setUpdateQuestion(question.question);
    setUpdateOptionA(question.optionA);
    setUpdateOptionB(question.optionB);
    setUpdateOptionC(question.optionC);
    setUpdateOptionD(question.optionD);
    setUpdateCorrectOption(question.correct_option);
    setUpdateIsActive(question.is_active);
    setUpdateDialogOpen(true);
  };

  const handleUpdateDialogClose = () => {
    setUpdateDialogOpen(false);
    setSelectedQuestionId(null);
    setUpdateQuestion("");
    setUpdateOptionA("");
    setUpdateOptionB("");
    setUpdateOptionC("");
    setUpdateOptionD("");
    setUpdateCorrectOption(1);
    setUpdateIsActive(true);
  };

  const handleRemoveQuestion = () => {
    axios.delete(`https://quizapi.cricfan.tv/admin/live-quiz-questions/${selectedQuestionId}`, { params: { password } })
      .then(response => {
        console.log('Question removed successfully');
        setRemoveDialogOpen(false);
        fetchQuestions(quizId);
      })
      .catch(error => {
        toast.error(error.response.data.message);
        console.error('Error removing question:', error);
      });
  };

  const handleRemoveDialogOpen = (question) => {
    setSelectedQuestionId(question.question_id);
    setRemoveDialogOpen(true);
  };

  const handleRemoveDialogClose = () => {
    setRemoveDialogOpen(false);
    setSelectedQuestionId(null);
  };

  const handleAddQuestion = () => {
    axios.post('https://quizapi.cricfan.tv/admin/live-quiz-questions', {
      live_quiz_id: quizId,
      question: addQuestion,
      optionA: addOptionA,
      optionB: addOptionB,
      optionC: addOptionC,
      optionD: addOptionD,
      correct_option: addCorrectOption,
      is_active: addIsActive
    }, { params: { password } })
      .then(response => {
        console.log('Question added successfully');
        setAddDialogOpen(false);
        fetchQuestions(quizId);
      })
      .catch(error => {
        toast.error(error.response.data.message);
        console.error('Error adding question:', error);
      });
  };

  const handlePasswordSubmit = () => {
    setPasswordDialogOpen(false);
  };

  return (
    <div>
      <h2 style={{ color: '#AF9661' }}>Quiz Question Management</h2>
      <Dialog open={passwordDialogOpen} onClose={() => { }} disableBackdropClick>
        <DialogTitle>Enter Password</DialogTitle>
        <DialogContent>
          <TextField
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePasswordSubmit} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Submit</Button>
        </DialogActions>
      </Dialog>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
        <Button onClick={() => setAddDialogOpen(true)} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Add Question</Button>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>ID</TableCell>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Question</TableCell>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Option A</TableCell>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Option B</TableCell>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Option C</TableCell>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Option D</TableCell>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Correct Option</TableCell>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Active</TableCell>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questions.map(question => (
              <TableRow key={question.question_id}>
                <TableCell>{question.question_id}</TableCell>
                <TableCell>{question.question}</TableCell>
                <TableCell>{question.optionA}</TableCell>
                <TableCell>{question.optionB}</TableCell>
                <TableCell>{question.optionC}</TableCell>
                <TableCell>{question.optionD}</TableCell>
                <TableCell>{question.correct_option}</TableCell>
                <TableCell>{question.is_active ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  <Button onClick={() => handleUpdateDialogOpen(question)} variant="contained" color="primary" style={{ marginRight: '8px' }}>Update</Button>
                  <Button onClick={() => handleRemoveDialogOpen(question)} variant="contained" color="secondary">Remove</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={updateDialogOpen} onClose={handleUpdateDialogClose}>
        <DialogTitle>Update Question</DialogTitle>
        <DialogContent>
          <TextField
            label="Question"
            value={updateQuestion}
            onChange={(e) => setUpdateQuestion(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Option A"
            value={updateOptionA}
            onChange={(e) => setUpdateOptionA(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Option B"
            value={updateOptionB}
            onChange={(e) => setUpdateOptionB(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Option C"
            value={updateOptionC}
            onChange={(e) => setUpdateOptionC(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Option D"
            value={updateOptionD}
            onChange={(e) => setUpdateOptionD(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Correct Option"
            value={updateCorrectOption}
            onChange={(e) => setUpdateCorrectOption(parseInt(e.target.value))}
            type="number"
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Active"
            value={updateIsActive}
            onChange={(e) => setUpdateIsActive(e.target.value === 'true')}
            fullWidth
            variant="outlined"
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdateDialogClose} color="primary">Cancel</Button>
          <Button onClick={handleUpdateQuestion} color="primary">Update</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={removeDialogOpen} onClose={handleRemoveDialogClose}>
        <DialogTitle>Remove Question</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to remove this question?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemoveDialogClose} color="primary">Cancel</Button>
          <Button onClick={handleRemoveQuestion} color="secondary">Remove</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)}>
        <DialogTitle>Add New Question</DialogTitle>
        <DialogContent>
          <TextField
            label="Question"
            value={addQuestion}
            onChange={(e) => setAddQuestion(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Option A"
            value={addOptionA}
            onChange={(e) => setAddOptionA(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Option B"
            value={addOptionB}
            onChange={(e) => setAddOptionB(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Option C"
            value={addOptionC}
            onChange={(e) => setAddOptionC(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Option D"
            value={addOptionD}
            onChange={(e) => setAddOptionD(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Correct Option"
            value={addCorrectOption}
            onChange={(e) => setAddCorrectOption(parseInt(e.target.value))}
            type="number"
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Active"
            value={addIsActive}
            onChange={(e) => setAddIsActive(e.target.value === 'true')}
            fullWidth
            variant="outlined"
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddDialogOpen(false)} color="primary">Cancel</Button>
          <Button onClick={handleAddQuestion} color="primary">Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

<ToastContainer />

export default GiveawayAdminLiveQuizQuestions;