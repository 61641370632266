import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField
} from '@mui/material';

function GiveawayAdminWinnersDraw() {
  const [giveaways, setGiveaways] = useState([]);
  const [selectedGiveawayId, setSelectedGiveawayId] = useState(null);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [updatePrizeTitle, setUpdatePrizeTitle] = useState("");
  const [updateWinnersList, setUpdateWinnersList] = useState("");
  const [updateWinnerName, setUpdateWinnerName] = useState("");
  const [addPrizeTitle, setAddPrizeTitle] = useState("");
  const [addWinnersList, setAddWinnersList] = useState("");
  const [addWinnerName, setAddWinnerName] = useState("");
  const [password, setPassword] = useState('');
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(true);

  useEffect(() => {
    if (!passwordDialogOpen) {
      fetchGiveaways();
    }
  }, [passwordDialogOpen]);

  const fetchGiveaways = () => {
    axios.get('https://scoreapi.cricfan.tv/api/get_winnerslist')
      .then(response => {
        setGiveaways(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching giveaways:', error);
      });
  };

  const handleUpdateGiveaway = () => {
    axios.post('https://scoreapi.cricfan.tv/api/post_update_giveawaywinnerslist', {
      id: selectedGiveawayId,
      prizetitle: updatePrizeTitle,
      winnerslist: updateWinnersList,
      winnersname: updateWinnerName,
      password
    })
      .then(response => {
        console.log('Giveaway updated successfully');
        setUpdateDialogOpen(false);
        fetchGiveaways();
      })
      .catch(error => {
        console.error('Error updating giveaway:', error);
      });
  };

  const handleUpdateDialogOpen = (giveaway) => {
    setSelectedGiveawayId(giveaway.id);
    setUpdatePrizeTitle(giveaway.prize_title);
    setUpdateWinnersList(giveaway.winners_list);
    setUpdateWinnerName(giveaway.winner_name);
    setUpdateDialogOpen(true);
  };

  const handleUpdateDialogClose = () => {
    setUpdateDialogOpen(false);
    setSelectedGiveawayId(null);
    setUpdatePrizeTitle("");
    setUpdateWinnersList("");
    setUpdateWinnerName("");
  };

  const handleRemoveGiveaway = () => {
    axios.post('https://scoreapi.cricfan.tv/api/post_remove_giveawaywinnerslist', {
      id: selectedGiveawayId,
      password
    })
      .then(response => {
        console.log('Giveaway removed successfully');
        setRemoveDialogOpen(false);
        fetchGiveaways();
      })
      .catch(error => {
        console.error('Error removing giveaway:', error);
      });
  };

  const handleRemoveDialogOpen = (giveaway) => {
    setSelectedGiveawayId(giveaway.id);
    setRemoveDialogOpen(true);
  };

  const handleRemoveDialogClose = () => {
    setRemoveDialogOpen(false);
    setSelectedGiveawayId(null);
  };

  const handleAddGiveaway = () => {
    axios.post('https://scoreapi.cricfan.tv/api/post_add_giveawaywinnerslist', {
      prizetitle: addPrizeTitle,
      winnerslist: addWinnersList,
      winnersname: addWinnerName,
      password
    })
      .then(response => {
        console.log('Giveaway added successfully');
        setAddDialogOpen(false);
        fetchGiveaways();
      })
      .catch(error => {
        console.error('Error adding giveaway:', error);
      });
  };

  const handlePasswordSubmit = () => {
    setPasswordDialogOpen(false);
  };

  return (
    <div>
      <h2 style={{ color: '#AF9661' }}>Giveaway Management</h2>
      <Dialog open={passwordDialogOpen} onClose={() => { }} disableBackdropClick>
        <DialogTitle>Enter Password</DialogTitle>
        <DialogContent>
          <TextField
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePasswordSubmit} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Submit</Button>
        </DialogActions>
      </Dialog>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
        <Button onClick={() => setAddDialogOpen(true)} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Add Giveaway</Button>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>ID</TableCell>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Prize Title</TableCell>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Winners List</TableCell>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Winner Name</TableCell>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Created On</TableCell>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Updated On</TableCell>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {giveaways.map(giveaway => (
              <TableRow key={giveaway.id}>
                <TableCell>{giveaway.id}</TableCell>
                <TableCell>{giveaway.prize_title}</TableCell>
                <TableCell>{giveaway.winners_list}</TableCell>
                <TableCell>{giveaway.winner_name}</TableCell>
                <TableCell>{new Date(giveaway.created_on).toLocaleString()}</TableCell>
                <TableCell>{new Date(giveaway.updated_on).toLocaleString()}</TableCell>
                <TableCell>
                  <Button onClick={() => handleUpdateDialogOpen(giveaway)} variant="contained" color="primary" style={{ marginRight: '8px' }}>Update</Button>
                  <Button onClick={() => handleRemoveDialogOpen(giveaway)} variant="contained" color="secondary">Remove</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={updateDialogOpen} onClose={handleUpdateDialogClose}>
        <DialogTitle>Update Giveaway</DialogTitle>
        <DialogContent>
          <TextField
            label="Prize Title"
            value={updatePrizeTitle}
            onChange={(e) => setUpdatePrizeTitle(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Winners List"
            value={updateWinnersList}
            onChange={(e) => setUpdateWinnersList(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Winner Name"
            value={updateWinnerName}
            onChange={(e) => setUpdateWinnerName(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdateGiveaway} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Update</Button>
          <Button onClick={handleUpdateDialogClose} variant="contained" color="secondary">Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={removeDialogOpen} onClose={handleRemoveDialogClose}>
        <DialogTitle>Remove Giveaway Winner</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to remove this giveaway winner?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemoveGiveaway} variant="contained" color="secondary">Remove</Button>
          <Button onClick={handleRemoveDialogClose} variant="contained">Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)}>
        <DialogTitle>Add Giveaway</DialogTitle>
        <DialogContent>
          <TextField
            label="Prize Title"
            value={addPrizeTitle}
            onChange={(e) => setAddPrizeTitle(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Winners List"
            value={addWinnersList}
            onChange={(e) => setAddWinnersList(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Winner Name"
            value={addWinnerName}
            onChange={(e) => setAddWinnerName(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddGiveaway} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Add</Button>
          <Button onClick={() => setAddDialogOpen(false)} variant="contained" color="secondary">Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default GiveawayAdminWinnersDraw;