import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import Nav from './Nav';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoadingIcon from './LoadingIcon'; // Import your loading icon component
import TelegramIcon from './TelegramIcon'; // Import the TelegramIcon component
import './App.css';
import './UserProfile.css';
import { TextField, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { showSuccessPopup, showErrorPopup } from './Notification';
import Congratsquizover from './Congratsquizover';


function Quizover() {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []); // Empty dependency array ensures this runs only once when the component mounts
  return (
    <div className="App" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/cricfan_bg.png)`, minHeight: '100vh', fontSize: '30px' }}>
      <Nav />
      <Congratsquizover />
      <Footer />
      <TelegramIcon />
    </div>
  );
}

export default Quizover;