import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField
} from '@mui/material';

function GiveawayAdminLiveQuizLeaderboard() {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [password, setPassword] = useState('');
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(true);
  const [quizId, setQuizId] = useState(0);

  const location = useLocation();

  useEffect(() => {
    if (!passwordDialogOpen) {
      setQuizId(location.state?.quizId);
      fetchLeaderboardData(location.state?.quizId);
    }
  }, [passwordDialogOpen]);

  const fetchLeaderboardData = (quizId) => {
    axios.get(`https://quizapi.cricfan.tv/admin/live-quiz-leaderboard/${quizId}`, { params: { password } })
      .then(response => {
        setLeaderboardData(response.data);
      })
      .catch(error => {
        toast.error(error.response.data.message);
        console.error('Error fetching leaderboard data:', error);
      });
  };

  const handlePasswordSubmit = () => {
    setPasswordDialogOpen(false);
  };

  return (
    <div>
      <h2 style={{ color: '#AF9661' }}>Quiz Leaderboard</h2>
      <Dialog open={passwordDialogOpen} onClose={() => { }} disableBackdropClick>
        <DialogTitle>Enter Password</DialogTitle>
        <DialogContent>
          <TextField
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePasswordSubmit} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Submit</Button>
        </DialogActions>
      </Dialog>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Leaderboard ID</TableCell>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>User ID</TableCell>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Answers</TableCell>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Created On</TableCell>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Updated On</TableCell>
              <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Deleted On</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leaderboardData.map(entry => (
              <TableRow key={entry.quiz_leaderboard_id}>
                <TableCell>{entry.quiz_leaderboard_id}</TableCell>
                <TableCell>{entry.user_id}</TableCell>
                <TableCell>{entry.correct_answers}</TableCell>
                <TableCell>{new Date(entry.created_on).toLocaleString()}</TableCell>
                <TableCell>{entry.updated_on ? new Date(entry.updated_on).toLocaleString() : 'N/A'}</TableCell>
                <TableCell>{entry.deleted_on ? new Date(entry.deleted_on).toLocaleString() : 'N/A'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

<ToastContainer />

export default GiveawayAdminLiveQuizLeaderboard;