import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import Nav from './Nav';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoadingIcon from './LoadingIcon'; // Import your loading icon component
import TelegramIcon from './TelegramIcon'; // Import the TelegramIcon component
import './App.css';
import QuizDashboard from './QuizDashboard';
import './UserProfile.css';
import { TextField, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { showSuccessPopup, showErrorPopup } from './Notification';



const GreenButton = styled(Button)({
  padding: '10px 20px',
  borderRadius: '5px',
  backgroundColor: '#01FF76',
  color: 'black',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#00cc60',
  },
});

function Quiz() {
  const [notificationGranted, setNotificationGranted] = useState(false);
  const [isSupported, setIsSupported] = useState(true);
  const [deviceType, setDeviceType] = useState(''); // To store device type

  useEffect(() => {
    // Check if Notifications API is supported
    // if (!('Notification' in window)) {
    //   if (deviceType === 'android') {
    //     return (
    //       <div>
    //         <p>On Android, enable notifications by:</p>
    //         <ol style={{ listStyleType: 'decimal' }}>
    //           <li>Go to browser settings.</li>
    //           <li>Select "Notifications".</li>
    //           <li>Enable notifications for this site.</li>
    //         </ol>
    //       </div>
    //     );
    //   } else if (deviceType === 'ios') {
    //     return (
    //       <div>
    //         <p>On iOS, enable notifications by:</p>
    //         <ol style={{ listStyleType: 'decimal' }}>
    //           <li>Use Safari browser.</li>
    //           <li>Go to "Settings" &gt; "Safari" &gt; "Advanced Settings".</li>
    //           <li>Enable notifications for Safari.</li>
    //           <li>Add this app to your home screen.</li>
    //         </ol>
    //       </div>
    //     );
    //   } else if (deviceType === 'desktop') {
    //     return (
    //       <div>
    //         <p>On Desktop, enable notifications by:</p>
    //         <ol >
    //           <li>Click the lock icon next to the URL in the address bar.</li>
    //           <li>Select "Site Settings".</li>
    //           <li>Enable "Notifications" for this site.</li>
    //         </ol>
    //       </div>
    //     );
    //   }
    // } else {
    //   // Check if notification permission is already granted
    //   if (Notification.permission === 'granted') {
    //     setNotificationGranted(true);
    //   }
    // }

    // Detect device type
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      setDeviceType('android');
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setDeviceType('ios');
    } else {
      setDeviceType('desktop');
    }
  }, []);

  //setNotificationGranted(false);

  // Function to request notification permission
  // const requestNotificationPermission = async () => {
  //   if ('Notification' in window && Notification.requestPermission) {
  //     try {
  //       const permission = await Notification.requestPermission();
  //       if (permission === 'granted') {
  //         setNotificationGranted(true);
  //         showSuccessPopup('Notification permission granted.');
  //       } else {
  //         showErrorPopup('Notification permission denied. Please follow the instructions');
  //       }
  //     } catch (error) {
  //       showErrorPopup('Error requesting notification permission. Please follow the instructions');
  //     }
  //   } else {
  //     showErrorPopup('Notification permission denied. Please follow the instructions');
  //   }
  // };

  // Notification instructions based on the device type
  // const renderNotificationInstructions = () => {
  //   if (deviceType === 'android') {
  //     return (
  //       <div>
  //         <p>On Android, enable notifications by:</p>
  //         <ol style={{ listStyleType: 'decimal' }}>
  //           <li>Go to browser settings.</li>
  //           <li>Select "Notifications".</li>
  //           <li>Enable notifications for this site.</li>
  //         </ol>
  //       </div>
  //     );
  //   } else if (deviceType === 'ios') {
  //     return (
  //       <div>
  //         <p>On iOS, enable notifications by:</p>
  //         <ol style={{ listStyleType: 'decimal' }}>
  //           <li>Use Safari browser.</li>
  //           <li>Go to "Settings" &gt; "Safari" &gt; "Advanced Settings".</li>
  //           <li>Enable notifications for Safari.</li>
  //           <li>Add this app to your home screen.</li>
  //         </ol>
  //       </div>
  //     );
  //   } else if (deviceType === 'desktop') {
  //     return (
  //       <div>
  //         <p>On Desktop, enable notifications by:</p>
  //         <ol >
  //           <li>Click the lock icon next to the URL in the address bar.</li>
  //           <li>Select "Site Settings".</li>
  //           <li>Enable "Notifications" for this site.</li>
  //         </ol>
  //       </div>
  //     );
  //   } else {
  //     return null;
  //   }
  // };

  // Check if notifications are supported
  // if (!isSupported) {
  //   return (
  //     <div style={centerStyle}>
  //       <p>Notifications are not supported on this browser or device.</p>
  //     </div>
  //   );
  // }

  // // Show the button if notification permission is not granted yet
  // if (!notificationGranted) {
  //   return (
  //     <div style={centerStyle}>
  //       <div>
  //         <GreenButton onClick={requestNotificationPermission}>
  //           Enable Notifications
  //         </GreenButton>
  //         {/* Instructions are now rendered BELOW the button */}
  //         <div style={{ marginTop: '20px' }}>
  //           {renderNotificationInstructions()}
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  // If notification permission is granted, render the original component
  return (
    <div className="App" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/cricfan_bg.png)`, minHeight: '100vh', fontSize: '30px' }}>
      <Nav />
      <QuizDashboard />
      <Footer />
      <TelegramIcon />
    </div>
  );
}

// Centering styles for the button
const centerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  textAlign: 'center',
};

export default Quiz;
