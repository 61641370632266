import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Confetti from "react-confetti"; // Import confetti package
import "./fonts.css";
import "./Leaderboard.css";
import axios from "axios";
import "./Leaderboardsmallview.css"; 
import "./Leaderboardsmallviewiphone.css";
import "./Leaderboardsmallviewiphonepromax.css";

function Leaderboard() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [leaderboardData, setLeaderboardData] = useState([]);
  const [currentUserData, setCurrentUserData] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false); // State for controlling confetti
  const location = useLocation();
  
  // Extract query parameters
  const queryParams = new URLSearchParams(location.search);
  const quizid = queryParams.get("quizid");
  const quizname = queryParams.get("quizname");
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
  }, []);
  // Fetch leaderboard data from API
  useEffect(() => {
    
    const fetchLeaderboard = async () => {
      try {
        const response = await axios.get(
          `https://quizapi.cricfan.tv/user/quiz/getStaticQuizLeaderboard`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtToken"),
              "Content-Type": "application/json",
            },
            params: { quiz_id: quizid },
          }
        );
        const { top10 } = response.data;
        setLeaderboardData(top10);

        // Find the current user data
        const currentUser = top10.find(
          (player) => player.is_current_user == true
        );
        setCurrentUserData(currentUser);

        // Show confetti for 2 seconds after fetching data
        setShowConfetti(true);
        setTimeout(() => {
          setShowConfetti(false);
        }, 4000);
      } catch (error) {
        console.error("Error fetching leaderboard data:", error);
      }
    };

    fetchLeaderboard();
  }, [quizid]);

  // Update window size for confetti
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Scroll to the top of the page when the component loads


  const getPlayerData = (rank) => {
    // Return the player data for a specific rank or placeholder if not available
    return (
      leaderboardData.find((player) => player.user_rank === rank) || {
        user_rank: rank,
        user_id: null,
        correct_answers: "Not Available",
      }
    );
  };

  return (
    <div className="congrats-wrapper">
      {/* Confetti Component */}
      {showConfetti && (
        <Confetti width={windowSize.width} height={windowSize.height} />
      )}

      <div id="leaderboard" className="leaderboard">
        <div className="leaderboard-header">
          <h1>LEADERBOARD</h1> {/* Display quiz name */}
        </div>

        {/* Current User Information */}
        {currentUserData && (
          <div className="player-info topboard col-sm-12">
            <div className="player-detail col-sm-12">
              <span>
                <img
                  className="player-icon"
                  src={`${process.env.PUBLIC_URL}/girl.svg`}
                  alt="Player Icon"
                />
                <span className="insideimg">
                  #{currentUserData?.user_rank ? currentUserData?.user_rank : "-"}
                </span>
              </span>
              <span className="outer-span col-sm-2 outspan">
                {currentUserData?.correct_answers !== undefined &&
                currentUserData?.correct_answers !== null
                  ? currentUserData?.correct_answers
                  : "-"}
                <span className="inner-span">ANSWERS</span>
              </span>
              <span className="outer-span col-sm-2 outspan">
                {currentUserData?.user_rank ? currentUserData?.user_rank : "-"}
                <span className="inner-span">RANK</span>
              </span>
              <span className="outer-span col-sm-2 outspan">
                {currentUserData?.user_rank ? currentUserData?.user_rank : "-"}
                <span className="inner-span">SCORE</span>
              </span>
              <span className="outer-span col-sm-2 outspan outer-span-name outspantxt">
                {quizname ? quizname : "Not Available"}
                <span className="inner-span">QUIZ</span>
              </span>
            </div>
          </div>
        )}

        {/* Podium - Top 3 */}
        <div className="podium">
          <div className="podium-stage">
            {/* Rank 2 - Second Place */}
            {(() => {
              const playerData = getPlayerData(2);
              return (
                <div className="second-place">
                  <div className="podium-info podium-info-2">
                    <span className="player-name">
                      {playerData.user_id ? `Player ${playerData.user_id}` : "Not Available"}
                    </span>
                    <span className="game-points">
                      {playerData.correct_answers === "Not Available"
                        ? "Not Available"
                        : `${playerData.correct_answers} point`}
                    </span>
                  </div>
                  <img
                    src={`${process.env.PUBLIC_URL}/girl.svg`}
                    alt="Second Place"
                  />
                  <span className="secondnumber">#{playerData.user_rank}</span>
                </div>
              );
            })()}
            {/* Rank 1 - First Place */}
            {(() => {
              const playerData = getPlayerData(1);
              return (
                <div className="first-place">
                  <div className="podium-info podium-info-1">
                    <span className="player-name">
                      {playerData.user_id ? `Player ${playerData.user_id}` : "Not Available"}
                    </span>
                    <span className="game-points">
                      {playerData.correct_answers === "Not Available"
                        ? "Not Available"
                        : `${playerData.correct_answers} point`}
                    </span>
                  </div>
                  <img
                    src={`${process.env.PUBLIC_URL}/girl.svg`}
                    alt="First Place"
                  />
                  <span className="firstnumber">#{playerData.user_rank}</span>
                </div>
              );
            })()}

            {/* Rank 3 - Third Place */}
            {(() => {
              const playerData = getPlayerData(3);
              return (
                <div className="third-place">
                  <div className="podium-info podium-info-3">
                    <span className="player-name">
                      {playerData.user_id ? `Player ${playerData.user_id}` : "Not Available"}
                    </span>
                    <span className="game-points">
                      {playerData.correct_answers === "Not Available"
                        ? "Not Available"
                        : `${playerData.correct_answers} point`}
                    </span>
                  </div>
                  <img
                    src={`${process.env.PUBLIC_URL}/girl.svg`}
                    alt="Third Place"
                  />
                  <span className="thirdnumber">#{playerData.user_rank}</span>
                </div>
              );
            })()}
          </div>
        </div>

        {/* Other Players - Ranks 4, 5 */}
        {[4, 5].map((rank) => {
          const playerData = getPlayerData(rank);

          return (
            <div key={rank} className={`player-info rank${rank}`}>
              <div className="player-detail">
                <span>
                  <img
                    className="player-icon"
                    src={`${process.env.PUBLIC_URL}/girl.svg`}
                    alt="Player Icon"
                  />
                  <span className="insideimg">#{playerData.user_rank}</span>
                </span>
                <span className="podiumtextrank outer-span podiumtextrankcenter">
                  {playerData.user_id ? `Player ${playerData.user_id}` : "Not Available"}
                </span>
                <span className="podiumtextrank outer-span">
                  {playerData.correct_answers === "Not Available"
                    ? "Not Available"
                    : `${playerData.correct_answers} QP`}
                </span>
              </div>
            </div>
          );
        })}

        <span className="emptygraybox"></span>
      </div>
    </div>
  );
}

export default Leaderboard;
