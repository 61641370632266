import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./fonts.css";
import "./staticquiz.css";
import "./staticquizsmallview.css";
import "./staticquizsmallviewiphone.css";
import "./staticquizsmallviewpromax.css";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const QuizCard = () => {
  const [timeLeft, setTimeLeft] = useState(10);
  const [selectedButton, setSelectedButton] = useState(null);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [quizData, setQuizData] = useState(null);
  const [totalquestions, setTotalQuestions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [questnumb, setQuestnumb] = useState(0);
  const [error, setError] = useState(null);
  const [submitMessage, setSubmitMessage] = useState(""); // For showing submission response
  const [quizOver, setQuizOver] = useState(false); // Track if the quiz is completed
  const navigate = useNavigate(); // Hook for navigation
  const location = useLocation();
  const [audio, setAudio] = useState(null);
  const [selectaudio, setSelectaudio] = useState(null);

  // Get query params from URL
  const getQueryParams = (query) => {
    return new URLSearchParams(query);
  };
  // Scroll to the top of the page when the component loads
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
  }, []);

  useEffect(() => {
    const newAudio = new Audio("/audio/timersound.mp4");
    setAudio(newAudio);
    
    return () => {
      // Cleanup: stop and release resources
      if (newAudio) {
        newAudio.pause();
        newAudio.currentTime = 2;
      }
    };
  }, []);

  useEffect(() => {
    const nAudio = new Audio("/audio/selectsound.mp4");
    setSelectaudio(nAudio);

    return () => {
      // Cleanup: stop and release resources
      if (nAudio) {
        nAudio.pause();
        nAudio.currentTime = 0;
      }
      
    };
  }, []);
  const playSound = () => {
    if (audio) {
      audio.currentTime = 2;
      audio.play().catch((err) => console.error("Failed to play sound:", err));
    }
  };

  const pauseSound = () => {
    if (audio) {
      audio.pause();
    }
  };

  const playSoundsel = () => {
    if (selectaudio) {
      selectaudio
        .play()
        .catch((err) => console.error("Failed to play sound:", err));
    }
  };

  const pauseSoundsel = () => {
    if (selectaudio) {
      selectaudio.pause();
    }
  };
  const fetchQuizData = async (quizId) => {
    try {
      if (totalquestions && questnumb && questnumb >= totalquestions) {
        setQuizOver(true);
        pauseSound();
        pauseSoundsel();
        navigate(`/quizover`, { replace: true }); // Using replace here
        return;
      }
      const response = await fetch(
        `https://quizapi.cricfan.tv/user/quiz/getQuizQuestion?quiz_id=${quizId}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        if (response.status === 404) {
          setQuizOver(true);
          pauseSound();
          pauseSoundsel();
          navigate(`/quizover`, { replace: true }); // Using replace here
          return;
        } else {
          throw new Error("Failed to fetch quiz data");
        }
      } else {
        if (audio) {
          playSound();
        }
      }
      const data = await response.json();
      document.querySelectorAll(".quiz-options .btn").forEach((btn) => {
        btn.style.backgroundColor = "black"; // Set background color to black
        btn.style.color = "white"; // Set text color to white
      });

      setQuizData(data);
      setSelectedButton(null);
      setButtonsDisabled(false);
      setLoading(false);
      setTimeLeft(10); // Reset the timer
      setQuestnumb(questnumb + 1);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    const queryParams = getQueryParams(location.search);
    const quizId = queryParams.get("quizid");
    setTotalQuestions(queryParams.get("total_questions"));

    fetchQuizData(quizId);
  }, [location.search]);

  useEffect(() => {
    playSound();
    if (timeLeft === 0 || quizOver) {
      const queryParams = getQueryParams(location.search);
      const quizId = queryParams.get("quizid");
      fetchQuizData(quizId);
      return;
    }

    // Play timer sound
    const timerId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [timeLeft, quizOver]);

  const handleButtonClick = async (buttonIndex) => {
    playSoundsel();
    setSelectedButton(buttonIndex);
    setButtonsDisabled(true);
    pauseSound(); // Stop timer sound
    const queryParams = getQueryParams(location.search);
    const quizId = queryParams.get("quizid");
    const questionId = quizData.question_id;

    const requestBody = {
      quiz_id: parseInt(quizId),
      question_id: questionId,
      answer_option: buttonIndex + 1, // Since buttonIndex starts from 0, we need to add 1 for the API
    };

    try {
      const response = await fetch(
        "https://quizapi.cricfan.tv/user/quiz/postQuizAnswer",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit answer");
      }

      const result = await response.json();

      // Wait 2.5 seconds and then fetch the next question
      setTimeout(() => {
        setSelectedButton(null);
        const queryParams = getQueryParams(location.search);
        const quizId = queryParams.get("quizid");
        playSound();
        pauseSoundsel();
        fetchQuizData(quizId);
      }, 2500);
    } catch (error) {
      setSubmitMessage(`Error: ${error.message}`);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="outermost staticquizs">
      <div className="quiz-card-container">
        <div className="quiz-card">
          <div className="quiz-timer-container">
            <div className="quiz-timer">
              <svg className="circle-svg">
                <circle
                  className="circle-background"
                  cx="50"
                  cy="50"
                  r="45"
                ></circle>
                <circle
                  className="circle-progress"
                  cx="50"
                  cy="50"
                  r="45"
                  style={{
                    strokeDashoffset: 283 - (283 * timeLeft) / 10,
                  }}
                ></circle>
              </svg>
              <div className="timer-text">{timeLeft}s</div>
            </div>
            <div className="cricwin-header">CRICWIN QUIZ</div>
          </div>
          <div
            id="quizvideo"
            className="quizvideo embed-responsive embed-responsive-16by9"
          >
            <iframe
              className="embed-responsive-item"
              src="https://www.youtube.com/embed/dQw4w9WgXcQ"
              allowFullScreen
              title="YouTube video"
            ></iframe>
          </div>
          <div id="quizcard" className="quizcard">
            <div className="quiz-question-number">
              Question {questnumb} / {totalquestions}
            </div>
            <div className="quiz-question mt-2">{quizData.question}</div>
            <div className="quiz-options mt-4">
              <button
                className={`btn ${
                  selectedButton === 0
                    ? "blink"
                    : buttonsDisabled
                    ? "disabled"
                    : "btn-dark"
                } mb-3`}
                onClick={() => handleButtonClick(0)}
                disabled={buttonsDisabled}
              >
                {quizData.optionA}
              </button>
              <button
                className={`btn ${
                  selectedButton === 1
                    ? "blink"
                    : buttonsDisabled
                    ? "disabled"
                    : "btn-dark"
                } mb-3`}
                onClick={() => handleButtonClick(1)}
                disabled={buttonsDisabled}
              >
                {quizData.optionB}
              </button>
              <button
                className={`btn ${
                  selectedButton === 2
                    ? "blink"
                    : buttonsDisabled
                    ? "disabled"
                    : "btn-dark"
                } mb-3`}
                onClick={() => handleButtonClick(2)}
                disabled={buttonsDisabled}
              >
                {quizData.optionC}
              </button>
              <button
                className={`btn ${
                  selectedButton === 3
                    ? "blink"
                    : buttonsDisabled
                    ? "disabled"
                    : "btn-dark"
                } mb-3`}
                onClick={() => handleButtonClick(3)}
                disabled={buttonsDisabled}
              >
                {quizData.optionD}
              </button>
            </div>
            <div className="submit-message mt-2">{submitMessage}</div>{" "}
            {/* Submission feedback */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizCard;
