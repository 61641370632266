import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField
} from '@mui/material';

function GiveawayAdminQuizList() {
  const [quizzes, setQuizzes] = useState([]);
  const [selectedQuizId, setSelectedQuizId] = useState(null);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [updateQuizName, setUpdateQuizName] = useState('');
  const [updateDescription, setUpdateDescription] = useState('');
  const [updateCoinsRequired, setUpdateCoinsRequired] = useState(50);
  const [updateSeconds, setUpdateSeconds] = useState(120);
  const [updateTotalQuestions, setUpdateTotalQuestions] = useState(3);
  const [addQuizName, setAddQuizName] = useState('');
  const [addDescription, setAddDescription] = useState('');
  const [addCoinsRequired, setAddCoinsRequired] = useState(50);
  const [addSeconds, setAddSeconds] = useState(10);
  const [addTotalQuestions, setAddTotalQuestions] = useState(3);

  const [updateStartDate, setUpdateStartDate] = useState('');
  const [updateEndDate, setUpdateEndDate] = useState('');
  const [addStartDate, setAddStartDate] = useState('');
  const [addEndDate, setAddEndDate] = useState('');

  const [password, setPassword] = useState('');
  const [dialogOpen, setDialogOpen] = useState(true);

  const navigate = useNavigate();

  const handleDialogClose = () => {
    setDialogOpen(false);
    fetchQuizzes();
  };

  function convertUTCtoIST(utcDate) {
    const date = new Date(utcDate);
    //date.setHours(date.getHours() + 5);
    //date.setMinutes(date.getMinutes() + 30);
    return date;
  }

  const fetchQuizzes = () => {
    axios.get('https://quizapi.cricfan.tv/admin/quiz', { params: { password } })
      .then(response => {
        setQuizzes(response.data);
      })
      .catch(error => {
        toast.error(error.response.data.message);
        console.error('Error fetching quizzes:', error);
      });
  };

  const handleUpdateQuiz = () => {

    var parts = new Date(updateStartDate);
    var formattedStartDate = parts.toISOString().slice(0, 16).replace('T', ' ') + ':00';

    var parts = new Date(updateEndDate);
    var formattedEndDate = parts.toISOString().slice(0, 16).replace('T', ' ') + ':00';

    console.log(updateTotalQuestions);

    const formData = new FormData();
    formData.append('quiz_name', updateQuizName);
    formData.append('description', updateDescription);
    formData.append('coins_required', updateCoinsRequired);
    formData.append('seconds', updateSeconds);
    formData.append('total_questions', updateTotalQuestions);
    formData.append('start_date', formattedStartDate);
    formData.append('end_date', formattedEndDate);


    let queryParams = `?password=${password}`;

    axios.put(`https://quizapi.cricfan.tv/admin/quiz/${selectedQuizId}/${queryParams}`, formData)
      .then(response => {
        console.log('Quiz updated successfully');
        setUpdateDialogOpen(false);
        fetchQuizzes();
      })
      .catch(error => {
        toast.error(error.response.data.message);
        console.error('Error updating quiz:', error);
      });
  };

  const handleDeleteQuiz = (quiz) => {

    setSelectedQuizId(quiz.quiz_id);

    let queryParams = `?password=${password}`;

    axios.delete(`https://quizapi.cricfan.tv/admin/quiz/${selectedQuizId}/${queryParams}`)
      .then(response => {
        console.log('Quiz deleted successfully');
        setUpdateDialogOpen(false);
        fetchQuizzes();
      })
      .catch(error => {
        toast.error(error.response.data.message);
        console.error('Error updating quiz:', error);
      });
  };

  const handleLeaderboardPublish = (quiz) => {

    setSelectedQuizId(quiz.quiz_id);

    let queryParams = `?password=${password}`;

    axios.post(`https://quizapi.cricfan.tv/admin/quiz-leaderboard/${quiz.quiz_id}${queryParams}`)
      .then(response => {
        console.log('Quiz leaderboard published successfully');
        setUpdateDialogOpen(false);
        fetchQuizzes();
      })
      .catch(error => {
        toast.error(error.response.data.message);
        console.error('Error updating quiz:', error);
      });
  };

  const handleAddQuizQuestions = (quiz) => {
    setSelectedQuizId(quiz.quiz_id);
    navigate('/adminStaticQuizQuestions', { state: { quizId: quiz.quiz_id } });
  };

  const handleLeaderboardView = (quiz) => {
    setSelectedQuizId(quiz.quiz_id);
    navigate('/adminStaticQuizLeaderboard', { state: { quizId: quiz.quiz_id } });
  };

  const handleUpdateDialogOpen = (quiz) => {
    setSelectedQuizId(quiz.quiz_id);
    setUpdateQuizName(quiz.quiz_name);
    setUpdateDescription(quiz.description);
    setUpdateCoinsRequired(quiz.coins_required);
    setUpdateSeconds(quiz.seconds);
    setUpdateTotalQuestions(quiz.total_questions);
    setUpdateStartDate(quiz.start_date);
    setUpdateEndDate(quiz.end_date);
    setUpdateDialogOpen(true);
  };

  const handleAddQuiz = () => {

    var parts = new Date(addStartDate);
    var formattedStartDate = parts.toISOString().slice(0, 16).replace('T', ' ') + ':00';

    var parts = new Date(addEndDate);
    var formattedEndDate = parts.toISOString().slice(0, 16).replace('T', ' ') + ':00';

    const formData = new FormData();
    formData.append('quiz_name', addQuizName);
    formData.append('description', addDescription);
    formData.append('coins_required', addCoinsRequired);
    formData.append('seconds', addSeconds);
    formData.append('total_questions', addTotalQuestions);
    formData.append('start_date', formattedStartDate);
    formData.append('end_date', formattedEndDate);

    let queryParams = `?password=${password}`;

    axios.post(`https://quizapi.cricfan.tv/admin/quiz${queryParams}`, formData)
      .then(response => {
        console.log('Quiz added successfully');
        setAddDialogOpen(false);
        fetchQuizzes();
      })
      .catch(error => {
        toast.error(error.response.data.message);
        console.error('Error adding quiz:', error);
      });
  };

  return (
    <div>
      <Dialog open={dialogOpen} onClose={() => { }} disableBackdropClick>
        <DialogTitle>Enter Password</DialogTitle>
        <DialogContent>
          <TextField
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Submit</Button>
        </DialogActions>
      </Dialog>
      <div>
        <h2 style={{ color: '#AF9661' }}>Quiz Management</h2>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
          <Button onClick={() => setAddDialogOpen(true)} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Add Quiz</Button>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>ID</TableCell>
                <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Quiz Name</TableCell>
                <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Description</TableCell>
                <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Coins Required</TableCell>
                <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Seconds</TableCell>
                <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Total Questions</TableCell>
                <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quizzes.map(quiz => (
                <TableRow key={quiz.quiz_id}>
                  <TableCell>{quiz.quiz_id}</TableCell>
                  <TableCell>{quiz.quiz_name}</TableCell>
                  <TableCell>{quiz.description}</TableCell>
                  <TableCell>{quiz.coins_required}</TableCell>
                  <TableCell>{quiz.seconds}</TableCell>
                  <TableCell>{quiz.total_questions}</TableCell>
                  <TableCell>
                    <Button onClick={() => handleUpdateDialogOpen(quiz)} variant="contained" color="primary" style={{ marginRight: '8px' }}>Update</Button>
                    <Button onClick={() => handleAddQuizQuestions(quiz)} variant="contained" color="primary" style={{ marginRight: '8px' }}>Add Question</Button>
                    <Button onClick={() => handleLeaderboardPublish(quiz)} variant="contained" color="primary" style={{ marginRight: '8px' }}>Publish Leaderboard</Button>
                    <Button onClick={() => handleLeaderboardView(quiz)} variant="contained" color="primary" style={{ marginRight: '8px' }}>View Leaderboard</Button>
                    <Button onClick={() => handleDeleteQuiz(quiz)} variant="contained" color="primary" style={{ marginRight: '8px' }}>Delete</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Update Dialog */}
        <Dialog open={updateDialogOpen} onClose={() => setUpdateDialogOpen(false)}>
          <DialogTitle>Update Quiz</DialogTitle>
          <DialogContent>
            <TextField label="Quiz Name" value={updateQuizName} onChange={(e) => setUpdateQuizName(e.target.value)} fullWidth margin="normal" />
            <TextField label="Description" value={updateDescription} onChange={(e) => setUpdateDescription(e.target.value)} fullWidth margin="normal" />
            <TextField label="Coins Required" value={updateCoinsRequired} onChange={(e) => setUpdateCoinsRequired(e.target.value)} fullWidth margin="normal" />
            <TextField label="Seconds" value={updateSeconds} onChange={(e) => setUpdateSeconds(e.target.value)} fullWidth margin="normal" />
            <TextField label="Total Questions" value={updateTotalQuestions} onChange={(e) => setUpdateTotalQuestions(e.target.value)} fullWidth margin="normal" />
            <TextField
              label="Start Date and Time"
              type="datetime-local"
              value={updateStartDate ? convertUTCtoIST(updateStartDate).toISOString().slice(0, 16) : ''}
              onChange={(e) => setUpdateStartDate(new Date(e.target.value))}
              fullWidth
              variant="outlined"
              style={{ marginBottom: '16px' }}
            />
            <TextField
              label="End Date and Time"
              type="datetime-local"
              value={updateEndDate ? convertUTCtoIST(updateEndDate).toISOString().slice(0, 16) : ''}
              onChange={(e) => setUpdateEndDate(new Date(e.target.value))}
              fullWidth
              variant="outlined"
              style={{ marginBottom: '16px' }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleUpdateQuiz} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Update</Button>
            {/* <Button onClick={handleDeleteQuiz} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Delete</Button>
            <Button onClick={handleLeaderboardPublish} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Publish Leaderboard</Button>
            <Button onClick={handleAddQuizQuestions} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Add Questions</Button> */}
            <Button onClick={() => setUpdateDialogOpen(false)} variant="contained" color="secondary">Cancel</Button>
          </DialogActions>
        </Dialog>

        {/* Add Dialog */}
        <Dialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)}>
          <DialogTitle>Add Quiz</DialogTitle>
          <DialogContent>
            <TextField label="Quiz Name" value={addQuizName} onChange={(e) => setAddQuizName(e.target.value)} fullWidth margin="normal" />
            <TextField label="Description" value={addDescription} onChange={(e) => setAddDescription(e.target.value)} fullWidth margin="normal" />
            <TextField label="Coins Required" value={addCoinsRequired} onChange={(e) => setAddCoinsRequired(e.target.value)} fullWidth margin="normal" />
            <TextField label="Seconds" value={addSeconds} onChange={(e) => setAddSeconds(e.target.value)} fullWidth margin="normal" />
            <TextField label="Total Questions" value={addTotalQuestions} onChange={(e) => setAddTotalQuestions(e.target.value)} fullWidth margin="normal" />
            <TextField
              label="Start Date and Time"
              type="datetime-local"
              value={addStartDate ? convertUTCtoIST(addStartDate).toISOString().slice(0, 16) : ''}
              onChange={(e) => setAddStartDate(new Date(e.target.value))}
              fullWidth
              variant="outlined"
              style={{ marginBottom: '16px' }}
            />
            <TextField
              label="End Date and Time"
              type="datetime-local"
              value={addEndDate ? convertUTCtoIST(addEndDate).toISOString().slice(0, 16) : ''}
              onChange={(e) => setAddEndDate(new Date(e.target.value))}
              fullWidth
              variant="outlined"
              style={{ marginBottom: '16px' }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAddQuiz} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Add</Button>
            <Button onClick={() => setAddDialogOpen(false)} variant="contained" color="secondary">Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

<ToastContainer />

export default GiveawayAdminQuizList;