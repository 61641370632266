import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField
} from '@mui/material';

function GiveawayAdminLiveQuizControls() {
  const [questions, setQuestions] = useState([]);
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [updateQuestion, setUpdateQuestion] = useState("");
  const [updateOptionA, setUpdateOptionA] = useState("");
  const [updateOptionB, setUpdateOptionB] = useState("");
  const [updateOptionC, setUpdateOptionC] = useState("");
  const [updateOptionD, setUpdateOptionD] = useState("");
  const [updateCorrectOption, setUpdateCorrectOption] = useState(1);
  const [updateIsActive, setUpdateIsActive] = useState(true);
  const [addQuestion, setAddQuestion] = useState("");
  const [addOptionA, setAddOptionA] = useState("");
  const [addOptionB, setAddOptionB] = useState("");
  const [addOptionC, setAddOptionC] = useState("");
  const [addOptionD, setAddOptionD] = useState("");
  const [addCorrectOption, setAddCorrectOption] = useState(1);
  const [addIsActive, setAddIsActive] = useState(true);
  const [password, setPassword] = useState('');
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(true);
  const [quizId, setQuizId] = useState(0);

  const location = useLocation();

  useEffect(() => {
    if (!passwordDialogOpen) {
      setQuizId(location.state?.quizId);
      fetchQuestions(location.state?.quizId);
    }
  }, [passwordDialogOpen]);

  const fetchQuestions = (quizId) => {
    axios.get(`https://quizapi.cricfan.tv/admin/live-quiz-questions/${quizId}`, { params: { password } })
      .then(response => {
        setQuestions(response.data);
      })
      .catch(error => {
        toast.error(error.response.data.message);
        console.error('Error fetching questions:', error);
      });
  };

  const handleStartQuiz = () => {
    axios.post(`https://quizapi.cricfan.tv/admin/livequizcontrols/startquiz/${quizId}`, null, { params: { password } })
      .then(response => {
        toast.success('Quiz started successfully');
      })
      .catch(error => {
        toast.error(error.response.data.message);
        console.error('Error starting quiz:', error);
      });
  };

  const handleStopQuiz = () => {
    axios.post(`https://quizapi.cricfan.tv/admin/livequizcontrols/stopquiz/${quizId}`, null, { params: { password } })
      .then(response => {
        toast.success('Quiz stopped successfully');
      })
      .catch(error => {
        toast.error(error.response.data.message);
        console.error('Error stopping quiz:', error);
      });
  };

  const handlePostQuestion = (questionId) => {
    axios.post(`https://quizapi.cricfan.tv/admin/livequizcontrols/quizquestion/${questionId}`, null, { params: { password } })
      .then(response => {
        toast.success('Question posted successfully');
      })
      .catch(error => {
        toast.error(error.response.data.message);
        console.error('Error posting question:', error);
      });
  };

  const handleStopQuestion = (questionId) => {
    axios.post(`https://quizapi.cricfan.tv/admin/livequizcontrols/quizquestionstop/${questionId}`, null, { params: { password } })
      .then(response => {
        toast.success('Question stopped successfully');
      })
      .catch(error => {
        toast.error(error.response.data.message);
        console.error('Error stopping question:', error);
      });
  };

  const handleShowAnswer = (questionId) => {
    axios.post(`https://quizapi.cricfan.tv/admin/livequizcontrols/quizquestionanswer/${questionId}`, null, { params: { password } })
      .then(response => {
        toast.success('Answer shown successfully');
      })
      .catch(error => {
        toast.error(error.response.data.message);
        console.error('Error showing answer:', error);
      });
  };

  const handleUpdateQuestion = () => {
    axios.put(`https://quizapi.cricfan.tv/admin/live-quiz-questions/${selectedQuestionId}`, {
      question: updateQuestion,
      optionA: updateOptionA,
      optionB: updateOptionB,
      optionC: updateOptionC,
      optionD: updateOptionD,
      correct_option: updateCorrectOption,
      is_active: updateIsActive
    }, { params: { password } })
      .then(response => {
        console.log('Question updated successfully');
        setUpdateDialogOpen(false);
        fetchQuestions(quizId);
      })
      .catch(error => {
        toast.error(error.response.data.message);
        console.error('Error updating question:', error);
      });
  };

  const handleUpdateDialogOpen = (question) => {
    setSelectedQuestionId(question.live_question_id);
    setUpdateQuestion(question.question);
    setUpdateOptionA(question.optionA);
    setUpdateOptionB(question.optionB);
    setUpdateOptionC(question.optionC);
    setUpdateOptionD(question.optionD);
    setUpdateCorrectOption(question.correct_option);
    setUpdateIsActive(question.is_active);
    setUpdateDialogOpen(true);
  };

  const handleUpdateDialogClose = () => {
    setUpdateDialogOpen(false);
    setSelectedQuestionId(null);
    setUpdateQuestion("");
    setUpdateOptionA("");
    setUpdateOptionB("");
    setUpdateOptionC("");
    setUpdateOptionD("");
    setUpdateCorrectOption(1);
    setUpdateIsActive(true);
  };

  const handleRemoveQuestion = () => {
    axios.delete(`https://quizapi.cricfan.tv/admin/live-quiz-questions/${selectedQuestionId}`, { params: { password } })
      .then(response => {
        console.log('Question removed successfully');
        setRemoveDialogOpen(false);
        fetchQuestions(quizId);
      })
      .catch(error => {
        toast.error(error.response.data.message);
        console.error('Error removing question:', error);
      });
  };

  const handleRemoveDialogOpen = (question) => {
    setSelectedQuestionId(question.live_question_id);
    setRemoveDialogOpen(true);
  };

  const handleRemoveDialogClose = () => {
    setRemoveDialogOpen(false);
    setSelectedQuestionId(null);
  };

  const handlePasswordSubmit = () => {
    setPasswordDialogOpen(false);
  };

  return (
    <div>
      <h2 style={{ color: '#AF9661' }}>Live Quiz Control Management</h2>
      <Dialog open={passwordDialogOpen} onClose={() => { }} disableBackdropClick>
        <DialogTitle>Enter Password</DialogTitle>
        <DialogContent>
          <TextField
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePasswordSubmit} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Submit</Button>
        </DialogActions>
      </Dialog>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
        <Button onClick={handleStartQuiz} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Start Quiz</Button>
        <Button onClick={handleStopQuiz} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Stop Quiz</Button>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Question</TableCell>
              <TableCell>Options</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questions.map((question) => (
              <TableRow key={question.live_question_id}>
                <TableCell>{question.question}</TableCell>
                <TableCell>
                  <div>A: {question.optionA}</div>
                  <div>B: {question.optionB}</div>
                  <div>C: {question.optionC}</div>
                  <div>D: {question.optionD}</div>
                </TableCell>
                <TableCell>
                  <Button onClick={() => handlePostQuestion(question.live_question_id)} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Post</Button>
                  <p></p>
                  <Button onClick={() => handleStopQuestion(question.live_question_id)} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Stop</Button>
                  <p></p>
                  <Button onClick={() => handleShowAnswer(question.live_question_id)} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Show Answer</Button>
                  <p></p>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Update Question Dialog */}
      <Dialog open={updateDialogOpen} onClose={handleUpdateDialogClose}>
        <DialogTitle>Update Question</DialogTitle>
        <DialogContent>
          <TextField
            label="Question"
            value={updateQuestion}
            onChange={(e) => setUpdateQuestion(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Option A"
            value={updateOptionA}
            onChange={(e) => setUpdateOptionA(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Option B"
            value={updateOptionB}
            onChange={(e) => setUpdateOptionB(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Option C"
            value={updateOptionC}
            onChange={(e) => setUpdateOptionC(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Option D"
            value={updateOptionD}
            onChange={(e) => setUpdateOptionD(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Correct Option"
            type="number"
            value={updateCorrectOption}
            onChange={(e) => setUpdateCorrectOption(Number(e.target.value))}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Is Active"
            type="checkbox"
            checked={updateIsActive}
            onChange={(e) => setUpdateIsActive(e.target.checked)}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdateDialogClose} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Cancel</Button>
          <Button onClick={handleUpdateQuestion} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Update</Button>
        </DialogActions>
      </Dialog>

      {/* Remove Question Dialog */}
      <Dialog open={removeDialogOpen} onClose={handleRemoveDialogClose}>
        <DialogTitle>Remove Question</DialogTitle>
        <DialogActions>
          <Button onClick={handleRemoveDialogClose} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Cancel</Button>
          <Button onClick={handleRemoveQuestion} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Remove</Button>
        </DialogActions>
      </Dialog>

      <ToastContainer />
    </div>
  );
}

export default GiveawayAdminLiveQuizControls;