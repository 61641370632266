import React, { useState, useEffect } from 'react';
import './fonts.css';
import './overlay.css';
import './Card.css';
import './CardMobile.css';
import Login from './Login';
import Signup from './Signup';
import { showSuccessPopup, showErrorPopup } from './Notification';
import CustomProgressBar from './CustomProgressBar';
import ReactGA from 'react-ga';

function Card(props) {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [startAllowed, setStartAllowed] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showSignup, setShowSignup] = useState(true);

  const toggleSignup = () => {
    setShowSignup(!showSignup);
  };

  const token = localStorage.getItem('jwtToken');

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
      setStartAllowed(calculateStartTimeAllowed());
    }, 1000);

    return () => clearTimeout(timer);
  });

  function calculateStartTimeAllowed() {
    const dbStartTimeUTC = new Date(props.cardData.startTime);
    const currentTimeIST = new Date();
    return (dbStartTimeUTC.getTime() - currentTimeIST.getTime()) < 0;
  }

  function calculateTimeLeft() {
    const dbStartTimeUTC = new Date(props.cardData.startTime);
    const currentTimeIST = new Date();
    const startDifference = dbStartTimeUTC.getTime() - currentTimeIST.getTime();
    if (startDifference > 0) {
      //start countdown
      let timeLeft = {};
      if (startDifference > 0) {
        timeLeft = {
          days: Math.floor(startDifference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((startDifference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((startDifference / 1000 / 60) % 60),
          seconds: Math.floor((startDifference / 1000) % 60),
        };
      } else {
        timeLeft = {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0
        }
      }
      return timeLeft;
    } else {
      //end countdown
      const dbEndTimeUTC = new Date(props.cardData.endTime);
      const difference = dbEndTimeUTC.getTime() - currentTimeIST.getTime();
      let timeLeft = {};
      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      } else {
        timeLeft = {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0
        }
      }
      return timeLeft;
    }
  };

  const handleEnterNowClick = () => {
    var reactLabel = "Competition - " + props.cardData.title;
    if (!startAllowed) {
      reactLabel = reactLabel + " COMING SOON";
    }
    if (ticketsLeft <= 0) {
      reactLabel = reactLabel + " SOLD OUT";
    }
    if (timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0) {
      reactLabel = reactLabel + " OVER";
    }
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: reactLabel
    });

    if (!startAllowed) {
      showErrorPopup("Competition is yet to start");
      return;
    }
    if (ticketsLeft <= 0) {
      showErrorPopup("Competition is sold out");
      return;
    }
    if (timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0) {
      showErrorPopup("Competition is now over");
      return;
    }

    if (!token || token === 'null') {
      setShowOverlay(true);
    } else {
      const apiEndpoint = `https://scoreapi.cricfan.tv/api/get_following_prize_v2?token=${token}&prize_id=${props.cardData.id}&prize_name=${props.cardData.title}`;
      fetch(apiEndpoint)
        .then(response => response.json())
        .then(data => {
          if (data.message === 'expire') {
            localStorage.setItem('jwtToken', null);
            setShowOverlay(true);
          } else {
            if (data.status === 200) {
              showSuccessPopup(data.message);
            } else {
              showErrorPopup(data.message);
            }
            props.handleShowThankYou(true);
          }
        })
        .catch(error => {
          showErrorPopup(error.message);
          console.error('Error:', error);
        });
    }
  };

  const hideOverlay = () => {
    setShowOverlay(false);
  };

  const hideAndShowThankYou = () => {
    setShowOverlay(false);
    props.handleShowThankYou(true);
  }

  const ticketsLeft = props.cardData.maxTicket - props.cardData.row_count;
  var progressPercentage = (ticketsLeft / props.cardData.maxTicket) * 100;
  if (progressPercentage < 40) {
    progressPercentage = 40;
  }
  if (ticketsLeft == 0) {
    progressPercentage = 0;
  }
  const ticketText = 'Entries Left: ' + ticketsLeft;

  return (
    <div className="col-lg-4 col-md-6 mx-auto parent-div">
      {showOverlay && (
        <div className="overlay">
          <div className="overlay-content">
            <div className="close-icon" onClick={() => setShowOverlay(false)}>
              <i className="fas fa-times"></i>
            </div>
            {showSignup ? (
              <Signup prizeId={props.cardData.id} prizeText={props.cardData.title} hideOverlay={hideOverlay} hideAndShowThankYou={hideAndShowThankYou} />
            ) : (
              <Login prizeId={props.cardData.id} prizeText={props.cardData.title} hideOverlay={hideOverlay} hideAndShowThankYou={hideAndShowThankYou} />
            )}
            <div className="text-center mt-2">
              <p className="mb-0" style={{ color: 'white' }}>
                {showSignup ? "Existing User? " : "New User? "}
                <span onClick={toggleSignup} style={{ cursor: 'pointer', color: 'white', textDecoration: 'underline' }}>
                  {showSignup ? "Login here" : "Signup here"}
                </span>
                .
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="card mb-5 mb-lg-0">
        <div className="card-class">
          <h5 className="card-title text-uppercase text-center header-style"></h5>
          {props.cardData.image && <img className="image-style" src={`${props.cardData.image}`} alt={props.cardData.title} />}
          <div className="row guaranteed-row">
            <div className="col text-style guaranteed-row">
              <p>{props.cardData.guaranteedDraw}</p>
            </div>
          </div>
          <div className="row progress-bar-row">
            <div className="col">
              <CustomProgressBar value={progressPercentage} min={0} max={props.cardData.maxTicket} labelText={ticketText} />
            </div>
          </div>
          <div className="row countdown-class">
            <div className="col countdown-box-style">
              <p className="countdown-number countdown-number-style">{timeLeft.days}</p>
              <p className="placeholder-style">Days</p>
            </div>
            <div className="col countdown-box-style">
              <p className="countdown-number countdown-number-style">{timeLeft.hours}</p>
              <p className="placeholder-style">Hours</p>
            </div>
            <div className="col countdown-box-style">
              <p className="countdown-number countdown-number-style">{timeLeft.minutes}</p>
              <p className="placeholder-style">Mins</p>
            </div>
            <div className="col countdown-box-style">
              <p className="countdown-number countdown-number-style">{timeLeft.seconds}</p>
              <p className="placeholder-style">Secs</p>
            </div>
          </div>
          <div className="d-grid">
            <button className="text-uppercase button-style" onClick={handleEnterNowClick}>
              {!startAllowed ? "COMING SOON" : ticketsLeft <= 0 ? "SOLD OUT" :
                (timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0) ? "Competition Over" :
                  "Enter Now"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;